
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import bannerUpload from '../../operate/bannerUpload/index.vue';
import color from '../../operate/color/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

@Component({
  name: 'headNav',
  components: {bannerUpload, color, imgLoad},
})
export default class extends Vue {
  static metadata = {
    name: '子页面配置',
    value: 'headNav',
    icon: '',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            style: {
              type: 'object',
              properties: {
                background: {
                  title: '页面背景色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#ffffff',
                },
                top: {
                  title: '距离头部',
                  type: 'number',
                  'ui:widget': 'ElSlider',
                  multipleOf: 1,
                  minimum: 0,
                  maximum: 1000,
                },
              },
            },
            isShowCurrentPositioning: {
              type: 'boolean',
              title: '是否显示当前定位',
              default: false,
            },
            headNavigation: {
              title: '头部导航',
              type: 'object',
              properties: {
                type: {
                  title: '导航类型',
                  type: 'string',
                  'ui:widget': 'RadioWidget',
                  enum: ['DIY', 'fixed', 'transparentFixed'],
                  enumNames: ['固定到导航', '普通导航', '透明导航'],
                },
                style: {
                  type: 'object',
                  properties: {
                    background: {
                      title: '导航背景色',
                      type: 'string',
                      'ui:widget': color,
                      default: '#ffffff',
                    },
                  },
                },
              },
            },
            banner: {
              title: '头部banner',
              type: 'object',
              properties: {
                sweiperArr: {
                  title: '图片',
                  type: 'array',
                  maxItems: 5,
                  items: {
                    type: 'object',
                    'ui:field': bannerUpload,
                  },
                },
                style: {
                  type: 'object',
                  properties: {
                    height: {
                      title: '高度',
                      type: 'number',
                      'ui:widget': 'ElSlider',
                      multipleOf: 10,
                      minimum: 100,
                      maximum: 1000,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {},
    paramData: [],
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setting: any;
  private height = '0px';
  private bannerData: Array<{img: string; url: string}> = [];
  private src = '';

  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('setting', {deep: true, immediate: true})
  changeSetting(val: {
    sweiperArr?: Array<{img: string; url: string}>;
    style?: {
      height: string;
    };
  }) {
    const bannerData = [] as Array<{img: string; url: string}>;
    if (val) {
      if (val.sweiperArr) {
        val.sweiperArr.forEach(item => {
          if (item.img) {
            bannerData.push(item);
          }
        });
      }
      this.bannerData = bannerData;
      if (val.style) {
        this.height = val.style.height ? val.style.height + 'px' : '0px';
      }
    }
  }
}
