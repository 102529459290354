
import {Component, Vue, Watch} from 'vue-property-decorator';
import {uITemplates, applications} from '../../../../resources';
import draggable from 'vuedraggable';
import menuList from '../components/menuList/index.vue';
import Back from '../components/back/index.vue';
import headNav from './components/review/headNav/index.vue';
import bottomMenu from './components/review/bottomMenu/index.vue';
import popUps from './components/review/popUps/index.vue';
import {ObjectId} from 'bson';
import html2canvas from 'html2canvas';
import axios from 'axios';
import {uploadImg} from '../../../../api/publicMethod';
import {promisify} from 'util';

const VueForm =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('@lljj/vue-json-schema-form/dist/vueJsonSchemaForm.esm').default;

import './components/review/search/index';
import './components/review/service/index';
import './components/review/shopList/index';
import './components/review/banner/index';
import './components/review/orderList/index';
import './components/review/userMineHead/index';
import './components/review/mineList/index';
import './components/review/storeInfo/index';
import './components/review/businessInfo/index';
import './components/review/selectOrderType/index';
import './components/review/settlementList/index';
import './components/review/remarks/index';
import './components/review/bottomSubmit/index';
import './components/review/orderStatus/index';
import './components/review/deleverInfo/index';
import './components/review/userOrderInfo/index';
import './components/review/addRemarks/index';
import './components/review/receivingAddress/index';
import './components/review/positionButton/index';
import './components/review/editAddress/index';
import './components/review/editUserInfo/index';
import './components/review/coupon/index';
import './components/review/couponUse/index';
import './components/review/accountBalance/index';
import './components/review/accountList/index';
import './components/review/withdrawal/index';
import './components/review/accountDetail/index';
import './components/review/refundSelectGoods/index';
import './components/review/refundSelectReason/index';
import './components/review/selectMap/index';
import './components/review/login/index';
import './components/review/shopOrderList/index';
import './components/review/createShop/index';
import './components/review/operationDetail/index';
import './components/review/goodsManege/index';
import './components/review/addGoods/index';
import './components/review/categoryManage/index';
import './components/review/specAndPro/index';
import './components/review/specDetails/index';
import './components/review/setSpec/index';
import './components/review/setAttr/index';
import './components/review/shopOrderSummary/index';
import './components/review/shopDailyAccount/index';
import './components/review/shopOrderStatistics/index';
import './components/review/shopMineHead/index';
import './components/review/textTips/index';
import './components/review/shopInfo/index';
import './components/review/shopMineStatus/index';
import './components/review/orderSetting/index';
import './components/review/tipBanner/index';
import './components/review/printerInfo/index';
import './components/review/printerList/index';
import './components/review/businessHours/index';
import './components/review/distributionOrderList/index';
import './components/review/distributionAddress/index';
import './components/review/orderRemarks/index';
import './components/review/distributionOrderInfo/index';
import './components/review/distributionProfit/index';
import './components/review/distributionOrderProductInfo/index';
import './components/review/distributionOrderSteps/index';
import './components/review/distributionOrderDetailFooter/index';
import './components/review/distributionGoToWork/index';
import './components/review/editRiderInfo/index';
import './components/review/sorterOrderList/index';
import './components/review/sorterOrderDetail/index';
import './components/review/distributionApplyForRegistration/index';
import './components/review/sorterApplyForRegistration/index';
import './components/review/distributorExamine/index';
import './components/review/distributionMineHead/index';
import './components/review/shopOrderAccountDetails/index';
import './components/review/shopOrderProductInfo/index';
import './components/review/shopOrderInfo/index';
import './components/review/shopOrderAmount/index';
import './components/review/shopFlow/index';
import './components/review/distributionOrderSummary/index';
import './components/review/distributionOrderAccountDetails/index';
import './components/review/distributionOrderStatistics/index';
import './components/review/distributionOrderAmount/index';
import './components/review/space/index';
import './components/review/sorterOrderAccountDetails/index';
import './components/review/agentOrder/index';
import './components/review/agentTask/index';
import './components/review/agentExpectedTime/index';
import './components/review/agentErrandFee/index';
import './components/review/agentPay/index';
import './components/review/agentOrderList/index';
import './components/review/agentOrderDetailAddress/index';
import './components/review/agentOrderDetailRemark/index';
import './components/review/agentOrderDetailCert/index';
import './components/review/agentOrderDetailCode/index';
import './components/review/agentOrderInfo/index';
import './components/review/agentOrderDetailIncome/index';
import './components/review/agentOrderDetailFooter/index';
import './components/review/userAgentOrderInfo/index';
import './components/review/userAgentOrderCode/index';
import './components/review/withdrawalStatus/index';
import './components/review/mallSeckill/index';
import './components/review/mallPopular/index';
import './components/review/mallRecommend/index';
import './components/review/mallCart/index';
import './components/review/mallMineOrder/index';
import './components/review/mallCategory/index';
import './components/review/mallGoodsInfo/index';
import './components/review/mallSelectCoupon/index';
import './components/review/mallGoodsDetails/index';
import './components/review/mallGoodsPay/index';
import './components/review/marketList/index';
import './components/review/marketGoodsDetail/index';
import './components/review/marketGoodsPay/index';
import './components/review/marketTask/index';
import './components/review/marketProductFee/index';
import './components/review/marketReleaseBut/index';
import './components/review/deliverGoods/index';
import './components/review/marketReleaseList/index';
import './components/review/marketGoodsInfo/index';
import './components/review/marketOrderList/index';
import './components/review/fab/index';
import './components/review/marketOrderStatus/index';
import './components/review/marketOrderDeleverInfo/index';
import './components/review/marketOrderInfo/index';
import './components/review/marketOrderGoodsInfo/index';
import './components/review/partTimeFormSubmit/index';
import './components/review/imageAd/index';
import './components/review/marketCateory/index';
import './components/review/operationWeekly/index';
import './components/review/operatingData/index';
import './components/review/refundProgress/index';
import './components/review/refundOrderStatus/index';
import './components/review/inviteesSummary/index';
import './components/review/searchPlaceholder/index';
import './components/review/todayDataSummary/index';
import './components/review/vipStatus/index';
import './components/review/vipCard/index';
import './components/review/vipPackage/index';
import './components/review/vipPay/index';
import './components/review/integralList/index';
import './components/review/telephoneCharges/index';
import './components/review/telephoneChargesDetail/index';
import './components/review/pickUpPoint/index';
import './components/review/competitionAuthentication/index';
import './components/review/competitionList/index';
import './components/review/competitionAnswer/index';
import './components/review/competitionResult/index';
import './components/review/userNamePhone/index';
import './components/review/competitionEntry/index';
import './components/review/competitionRankingList/index';
import './components/review/sorterOrderBinding/index';
import './components/review/collectCoupons/index';
import './components/review/cabinetInfo/index';
import './components/review/cabinetRequest/index';
import './components/review/cabinetTakeMeal/index';
import './components/review/shopChange/index';
import './components/review/shopSummaryMonth/index';
import './components/review/shopDailyTransactionSummary/index';
import './components/review/shopIcomeDetail/index';
import './components/review/shopRelatedInformation/index';
import './components/review/shopHistoricalFlow/index';
import './components/review/authorizedPosition/index';
import './components/review/selectPosition/index';
import './components/review/shopAccountList/index';
import './components/review/payInfo/index';
import {comp} from './comp';

//页面配置
const page = {
  headNav,
  bottomMenu,
  popUps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

interface CompSpec {
  name?: string;
  value?: string;
  operateData?: Array<{
    title: string;
    list: Array<{type: string; name: string; settingType?: string}>;
  }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setting?: any;
}
interface CompData {
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list?: any;
}
@Component({
  name: 'decConfig',
  components: {
    VueForm,
    draggable,
    menuList,
    Back,
    ...comp,
    ...page,
  },
})
export default class extends Vue {
  private loading = false;
  private configShow = 'page'; //page 页面配置  comp 组件配置
  private formData = {};
  private schema = {};
  private uiSchema = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private pageSpec: any = null;
  private delCompIndex: Array<number> = []; //删除组件index
  private dialogDel = false; //删除组件弹窗
  private dialogClear = false; //清除组件
  private operateTitle = ''; //当前选中组件
  private menuIndex = 0; //当前选中菜单
  private selectCompName = 'bottomMenu';
  private borderShow = 10001; //显示操作虚线框

  private setShow = ['0', '1', '2', '3', '4']; //操作栏面板折叠
  private compInfor: Array<CompSpec> = []; //选择的组件
  private operateShow = 'mainPage'; //当前选中组件
  private options = {filter: '.stopdrap', preventOnFilter: false}; //拖拽组件配置
  private compShow = ['1']; //选择组件面板折叠
  private compData: Array<CompData> = []; //可选择的组件
  private detailId = ObjectId.createFromHexString(
    this.$route.params.templateId,
  );
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private currentPageName = this.$route.query.name as string; //当前页面名称
  private pageCategory = '';

  async created() {
    //查询应用权限
    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          );
          return match;
        }),
      )
    ).find(() => true);
    this.$store.state.mallServices = '';
    this.$store.state.usedServices = '';
    this.$store.state.agentServices = '';
    this.$store.state.questionServices = '';
    if (application?.spec.enableServices) {
      application?.spec.enableServices.forEach(item => {
        if (item === '商城') {
          this.$store.state.mallServices = item;
        }
        if (item === '二手') {
          this.$store.state.usedServices = item;
        }
        if (item === '跑腿') {
          this.$store.state.agentServices = item;
        }
        if (item === '问卷') {
          this.$store.state.questionServices = item;
        }
      });
    }
    //渲染组件
    let compTitle = [] as Array<string>;
    const compData = [] as Array<CompData>;
    Object.keys(comp).forEach(key => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const compkey = comp[key]!;
      compTitle.push(compkey.metadata.type);
    });
    compTitle = Array.from(new Set(compTitle));
    compTitle.forEach(item => {
      compData.push({title: item, list: []});
    });
    compData.forEach(item => {
      Object.keys(comp).forEach(key => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const compkey = comp[key]!;
        if (item.title === compkey.metadata.type) {
          item.list.push(compkey);
        }
      });
    });
    const newCompData = [] as Array<CompData>;
    compData.forEach(item => {
      if (
        item.title === '基础' ||
        item.title === '订单' ||
        item.title === '我的' ||
        item.title === '早餐柜'
      ) {
        newCompData.push(item);
      } else {
        if (application?.spec.enableServices) {
          application?.spec.enableServices.forEach(appItem => {
            if (
              (item.title === '商城' || item.title === '商户') &&
              appItem === '商城'
            ) {
              newCompData.push(item);
            }
            if (
              (item.title === '配送端' || item.title === '分拣端') &&
              appItem === '配送'
            ) {
              newCompData.push(item);
            }
            if (item.title === '二手' && appItem === '二手') {
              newCompData.push(item);
            }
            if (item.title === '跑腿' && appItem === '跑腿') {
              newCompData.push(item);
            }
            if (item.title === '问卷' && appItem === '问卷') {
              newCompData.push(item);
            }
          });
        }
      }
    });
    this.compData = newCompData;

    //查询页面信息
    const uITemplate = (
      await uITemplates.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$eq(this.detailId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        ),
      )
    ).find(() => true);
    this.pageSpec = uITemplate?.spec.pages[this.currentPageName] ?? null;
    this.pageCategory =
      uITemplate?.spec.pages[this.currentPageName].category ?? '';
    // delete this.pageSpec.subPages[0].setting.headNavigation.setting;
    //设置操作组件的初始值
    this.operateTitle = bottomMenu.metadata.name;
    this.schema = bottomMenu.metadata.schema;
    this.uiSchema = bottomMenu.metadata.uiSchema;
    this.formData = {
      setting: this.pageSpec?.setting,
      subPages: this.pageSpec?.subPages,
    };
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  @Watch('formData', {deep: true})
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeformData(val: any) {
    // if (val.setting) {
    //   this.pageSpec.setting = val.setting;
    // }
    if (this.selectCompName !== 'popUps') {
      if (this.selectCompName === 'bottomMenu' && val.subPages) {
        if (val.subPages.length === 1) {
          this.menuIndex = 0;
        }
        this.pageSpec = val;
      } else if (val.setting) {
        if (this.selectCompName === 'headNav') {
          this.pageSpec.subPages[this.menuIndex].setting = val.setting;
        } else {
          this.pageSpec.subPages[this.menuIndex].components[
            this.borderShow
          ].setting = val.setting;
        }
      }
    }
  }
  //组件排序获取组件名称
  private getCompName(type: string) {
    return comp[type].metadata.name;
  }
  //切换组件配置 页面配置
  private switchConfig() {
    this.configShow = 'comp';
    this.operateTitle = '组件配置';
    this.borderShow = 10002;
  }
  //切换组件配置 弹窗配置
  private popUpsConfig() {
    this.borderShow = 10003;
  }
  // 切换底部菜单
  private changeMenu(index: number) {
    this.menuIndex = index;
  }
  //选择组件
  private selectComp(index: number, listIndex: number) {
    this.pageSpec.subPages[this.menuIndex].components.push({
      type: this.compData[index].list[listIndex].metadata.value,
      setting: {},
    });
    setTimeout(() => {
      this.changeComp(
        this.menuIndex,
        this.pageSpec.subPages[this.menuIndex].components.length - 1,
      );
    }, 300);
  }
  //清除组件
  private clearCompSumbit() {
    this.pageSpec.subPages[this.menuIndex].components = [];
    this.dialogClear = false;
  }
  //删除组件
  private delCompSumbit() {
    this.pageSpec.subPages[this.delCompIndex[0]].components.splice(
      this.delCompIndex[1],
      1,
    );
    this.dialogDel = false;
    if (this.configShow === 'page') {
      this.pageComp('bottomMenu', 10001);
    }
  }
  //删除组件弹窗
  private delCompPopUps(index: number, compIndex: number) {
    this.delCompIndex = [index, compIndex];
    this.dialogDel = true;
  }
  //选中组件
  private changeComp(index: number, compIndex: number) {
    this.configShow = 'page';
    this.schema = {};
    this.uiSchema = {};
    const compList = this.pageSpec.subPages[index].components[compIndex];
    this.borderShow = compIndex;
    this.selectCompName = compList.type;
    this.operateTitle = comp[compList.type].metadata.name;
    setTimeout(() => {
      this.schema = comp[compList.type].metadata.schema;
      this.uiSchema = comp[compList.type].metadata.uiSchema;
      this.formData = {
        setting: compList.setting,
      };
    }, 10);
    // this.schema = comp[compList.type].metadata.schema;
    // this.uiSchema = comp[compList.type].metadata.uiSchema;
  }
  //选中头部设置 页面设置
  private pageComp(name: string, index: number) {
    this.configShow = 'page';
    this.schema = {};
    this.uiSchema = {};
    this.borderShow = index;
    this.selectCompName = name;
    this.operateTitle = page[name].metadata.name;
    setTimeout(() => {
      this.schema = page[name].metadata.schema;
      this.uiSchema = page[name].metadata.uiSchema;
    }, 100);
    if (name === 'headNav') {
      this.formData = {
        setting: this.pageSpec?.subPages[this.menuIndex].setting,
      };
    } else if (name === 'bottomMenu') {
      this.formData = {
        setting: this.pageSpec?.setting,
        subPages: this.pageSpec?.subPages,
      };
    } else if (name === 'popUps') {
      this.formData = {
        setting: this.pageSpec?.subPages[this.menuIndex].setting,
      };
    }
  }
  //提交
  private async changeSubmit() {
    try {
      this.loading = true;
      const canvasId = this.$refs.html2canvas as HTMLElement;
      canvasId.scrollTop = 0;
      const canvas = await html2canvas(canvasId, {
        useCORS: true,
      });
      //const url = canvas.toDataURL('image/jpeg'); //图片地址
      const blob = await promisify(
        (callback: (error: Error | null, blob: Blob) => void) => {
          canvas.toBlob(blob =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            callback(blob ? null : new Error('无法获取图片'), blob as any),
          );
        },
      )();
      const res = await uploadImg(
        this.platformConfig.channels[0].channelId,
        this.platformConfig.applicationId,
        false,
        [],
      );
      const formData = new FormData();
      for (const key in res?.status?.upload?.data) {
        formData.append(key, res?.status?.upload?.data[key]);
      }
      formData.append('file', blob);
      if (res?.status?.upload?.url) {
        const urlRes = await axios.post(res?.status?.upload?.url, formData);
        this.pageSpec.image = urlRes.data.file;
        this.pageSpec.category = this.pageCategory;
        const uITemplate = await uITemplates.update(
          filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          update =>
            update.$set(s =>
              s(f => f('spec')('pages')(this.currentPageName), this.pageSpec),
            ),
        );
        if (uITemplate.length > 0) {
          this.$message.success('已保存');
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //移动组件更换当前选中的值
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private moveComp(e: any) {
    this.borderShow = e.relatedContext.index;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private setData(dataTransfer: any) {
    dataTransfer.setData('Text', '');
  }
  private backBtn() {
    this.$router.push({
      path:
        '/application/' +
        this.$route.params.applicationId +
        '/template/' +
        this.$route.params.templateId,
    });
  }
}
