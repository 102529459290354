
import {SubPage} from '@/externals/MaxCI-Page-v1';
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import upload from '../../operate/upload/index.vue';
import color from '../../operate/color/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';

@Component({
  name: 'bottomMenu',
  components: {upload, color, imgLoad},
})
export default class extends Vue {
  static metadata = {
    name: '页面设置',
    value: '',
    icon: '',
    schema: {
      definitions: {
        Thing: {
          type: 'object',
          properties: {
            name: {
              title: '子页面名称',
              type: 'string',
              default: '默认',
            },
            components: {
              type: 'array',
              'ui:hidden': true,
              items: {
                type: 'object',
              },
            },
            setting: {
              title: '',
              type: 'object',
              'ui:hidden': true,
            },
            tabItem: {
              title: '',
              type: 'object',
              properties: {
                text: {
                  title: '菜单名称',
                  type: 'string',
                  default: '默认',
                },
                images: {
                  title: '菜单图标',
                  type: 'array',
                  minItems: 2,
                  maxItems: 2,
                  items: {
                    type: 'ObjectId',
                    'ui:field': upload,
                  },
                  default: [
                    '600914214bcfb3703c64eee2',
                    '600914534bcfb3008464eee3',
                  ],
                },
                color: {
                  title: '默认字体颜色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#333333',
                },
                selectColor: {
                  title: '选中字体颜色',
                  type: 'string',
                  'ui:widget': color,
                  default: '#11A983',
                },
                fontSize: {
                  title: '默认字体大小',
                  type: 'number',
                  'ui:widget': 'ElSlider',
                  default: 14,
                  minimum: 10,
                  maximum: 50,
                  multipleOf: 1,
                },
                selectFontSize: {
                  title: '选中字体大小',
                  type: 'number',
                  'ui:widget': 'ElSlider',
                  default: 14,
                  minimum: 10,
                  maximum: 50,
                  multipleOf: 1,
                },
              },
            },
          },
        },
      },
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            isLoginShow: {
              type: 'boolean',
              title: '是否显示登录页',
              default: true,
            },
          },
        },
        subPages: {
          type: 'array',
          title: '新增子页面',
          minItems: 1,
          maxItems: 5,
          items: {
            $ref: '#/definitions/Thing',
          },
        },
      },
    },
    uiSchema: {
      subPages: {
        items: {
          tabItem: {
            images: {
              'ui:options': {
                addable: false,
                sortable: false,
                removable: false,
                showIndexNumber: true,
              },
            },
          },
        },
      },
    },
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private subPages: any;
  private action: '';
  private menuIndex = 0;
  private normalImg =
    'https://oss.tbservice.jsustore.cn/20210113151759375100nc9DFLFE.png';
  private passImg =
    'https://oss.tbservice.jsustore.cn/202101131519138664534f6P9GfD.png';
  private menuData: Array<SubPage> = [];
  created() {
    // console.log('subPages',this.subPages);
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('subPages', {immediate: true})
  changeHotWord(val: Array<SubPage>) {
    this.menuData = val;
  }
  private changeMenu(index: number) {
    this.menuIndex = index;
    this.$emit('changeMenu', index);
  }
}
