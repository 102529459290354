
import {Component, Vue, Prop} from 'vue-property-decorator';
import {ObjectId} from 'bson';
import {vueUtils} from '@lljj/vue-json-schema-form';
import uploadImage from '@/components/uploadImage/index.vue';

@Component({
  name: 'upload',
  components: {
    uploadImage,
  },
})
export default class extends Vue {
  @Prop()
  private rootFormData: Record<string, unknown>;
  @Prop()
  private curNodePath: string;

  private img = '';
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private index = 10;
  async created() {
    if (this.curNodePath.indexOf('tabItem') > -1) {
      const tabImages = this.curNodePath.split('.');
      this.index = Number(tabImages[tabImages.length - 1]);
    }
    const img = vueUtils.getPathVal(this.rootFormData, this.curNodePath);
    if (typeof img === 'string') {
      this.img = img;
    }
  }
  //选择图片
  private changeUploadImage(id: string) {
    this.img = id;
    vueUtils.setPathVal(this.rootFormData, this.curNodePath, this.img);
  }
}
