
import {SubPage} from '@/externals/MaxCI-Page-v1';
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import upload from '../../operate/upload/index.vue';
import color from '../../operate/color/index.vue';
import imgLoad from '../../operate/imgLoad/index.vue';
import popUpsList from '../../operate/popUpsList/index.vue';

@Component({
  name: 'popUps',
  components: {upload, color, imgLoad, popUpsList},
})
export default class extends Vue {
  static metadata = {
    name: '弹窗设置',
    value: '',
    icon: '',
    schema: {
      type: 'object',
      properties: {
        setting: {
          type: 'object',
          properties: {
            popUps: {
              type: 'object',
              properties: {
                coupon: {
                  title: '优惠券',
                  type: 'object',
                  properties: {
                    isOpen: {
                      type: 'boolean',
                      title: '开启',
                      default: false,
                    },
                    list: {
                      title: '优惠券弹窗列表',
                      type: 'array',
                      'ui:hidden':
                        '{{rootFormData.setting.popUps.coupon.isOpen === false}}',
                      minItems: 1,
                      items: {
                        type: 'object',
                        'ui:field': popUpsList,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    uiSchema: {},
  };
  @Prop()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private subPages: any;
  private action: '';
  private menuIndex = 0;
  private normalImg =
    'https://oss.tbservice.jsustore.cn/20210113151759375100nc9DFLFE.png';
  private passImg =
    'https://oss.tbservice.jsustore.cn/202101131519138664534f6P9GfD.png';
  private menuData: Array<SubPage> = [];
  created() {
    // console.log('subPages',this.subPages);
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  @Watch('subPages', {immediate: true})
  changeHotWord(val: Array<SubPage>) {
    this.menuData = val;
  }
  private changeMenu(index: number) {
    this.menuIndex = index;
    this.$emit('changeMenu', index);
  }
}
