var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.menuData.length > 1)?_c('div',{staticClass:"bottom_menu"},_vm._l((_vm.menuData),function(item,index){return _c('div',{key:index,staticClass:"menu_li",on:{"click":function($event){return _vm.changeMenu(index)}}},[(_vm.menuIndex !== index)?_c('img-load',{attrs:{"width":"27px","src":_vm.downloadUrl + item.tabItem.images[0]}}):_vm._e(),(_vm.menuIndex === index)?_c('img-load',{attrs:{"width":"27px","src":_vm.downloadUrl + item.tabItem.images[1]}}):_vm._e(),_c('span',{staticClass:"menu_text",style:({
        color:
          _vm.menuIndex === index ? item.tabItem.selectColor : item.tabItem.color,
        fontSize:
          _vm.menuIndex === index
            ? item.tabItem.selectFontSize + 'px'
            : item.tabItem.fontSize + 'px',
      })},[_vm._v(_vm._s(item.tabItem.text))])],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }