
import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'Back',
})
export default class extends Vue {
  mounted() {
    //监听浏览器返回键  重置路由
    if (window.history) {
      history.pushState(null, '', document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  }
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  }
  private goBack() {
    const applicationId = this.$route.params.applicationId;
    this.$store.state.applicationId = '';
    this.$router.replace({
      path: '/application/' + applicationId + '/template/index',
    });
  }
  private backBtn() {
    const applicationId = this.$route.params.applicationId;
    this.$store.state.applicationId = '';
    this.$router.push({
      path: '/application/' + applicationId + '/template/index',
    });
  }
}
